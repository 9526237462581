<template>
  <div class="privacy-policy">
    <div class="wrapper">
      <div class="privacy-policy__wrapper">
        <div class="privacy-policy__header">
          <h2 class="privacy-policy__title title">
            Политика конфиденциальности
          </h2>
        </div>
        <div class="privacy-policy__body">
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Введение
            </h6>
            <p>
              Настоящая Политика конфиденциальности персональных данных (далее –
              Политика конфиденциальности) действует в отношении всей
              информации, которую Общество с ограниченной ответственностью
              «ОциумСофт» - владелец сайта, может получить о Пользователе во
              время использования сайта. Состав данных подлежащих сбору,
              хранению, обработке и использованию определяется настоящей
              политикой конфиденциальности.
            </p>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Определение терминов
            </h6>
            <p>
              В настоящей Политике конфиденциальности используются следующие
              термины:
            </p>
            <ul>
              <li>
                <span>
                  «Сайт» – веб-страница, используемая пользователем в
                  соответствии с ее функциональностью.
                </span>
              </li>
              <li>
                <span>
                  «Администратор» – владелец сайта, а также уполномоченные
                  сотрудники по управлению сайтом, действующие от имени
                  владельца, которые непосредственно организуют и (или)
                  осуществляет обработку персональных данных, а также определяет
                  цели обработки персональных данных, состав персональных
                  данных, подлежащих обработке, действия (операции), совершаемые
                  с персональными данными.
                </span>
              </li>
              <li>
                <span>
                  «Персональные данные» - любая информация, относящаяся к прямо
                  или косвенно определенному или определяемому физическому лицу
                  (субъекту персональных данных), юридическому лицу (в случае
                  предоставления данных сотрудника).
                </span>
              </li>
              <li>
                <span>
                  «Обработка персональных данных» - любое действие (операция)
                  или совокупность действий (операций), совершаемых с
                  использованием средств автоматизации или без использования
                  таких средств с персональными данными, включая сбор, запись,
                  систематизацию, накопление, хранение, уточнение (обновление,
                  изменение), извлечение, использование, передачу
                  (распространение, предоставление, доступ), обезличивание,
                  блокирование, удаление, уничтожение персональных данных.
                </span>
              </li>
              <li>
                <span>
                  «Конфиденциальность персональных данных» - обязательное для
                  соблюдения Администратором или иным получившим доступ к
                  персональным данным лицом требование не допускать их
                  распространения без согласия субъекта персональных данных или
                  наличия иного законного основания.
                </span>
              </li>
              <li>
                <span>
                  «Пользователь» – лицо, имеющее доступ к Сайту, посредством
                  сети Интернет и непосредственно использующее его.
                </span>
              </li>
              <li>
                <span>
                  «Cookies» — небольшой фрагмент данных, отправленный
                  веб-сервером и хранимый на устройстве пользователя.
                </span>
              </li>
              <li>
                <span>
                  «IP-адрес» — уникальный сетевой адрес узла в компьютерной
                  сети, построенной по протоколу IP.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Общие положения
            </h6>

            <p>
              Использование Пользователем Сайта, принятия согласия использования
              Cookie означает согласие с настоящей Политикой конфиденциальности
              и условиями обработки персональных данных Пользователя.
            </p>

            <p>
              В случае несогласия с условиями Политики конфиденциальности
              Пользователь должен прекратить использование Сайта.
            </p>
            <p>
              Настоящая Политика конфиденциальности применяется только к
              настоящему сайту. Владелец Сайта не несет ответственность за сайты
              третьих лиц.
            </p>
            <p>
              Администратор не проверяет достоверность персональных данных,
              предоставляемых Пользователем Сайта.
            </p>
            <p>
              Сайт может содержать ссылки на сторонние ресурсы, которыми мы не
              управляем, в случае посещения таковой мы настоятельно рекомендуем
              ознакомится с Политикой конфиденциальности каждого посещаемого
              вами стороннего ресурса. Администратор не несет ответственность за
              содержание, политику конфиденциальности или действия любых
              сторонних сайтов и служб.
            </p>
            <p>
              Личные данные и иная информация Пользователей не передаются
              третьим лицам без их согласия, за исключением случаев,
              предусмотренных законодательством, и сведений, размещенных
              Пользователями по их собственной инициативе в открытом доступе на
              Сайте и его сервисах.
            </p>
            <p>
              В случае размещая на Сайте и его сервисах по собственной
              инициативе в открытом виде личные данные и другую информацию,
              Пользователи осознают, что указанная информация становится
              доступной третьим лицам с учетом особенностей архитектуры и
              функционала Сайта, и соглашаются с этим. В таком случае
              Администрация Сайта ответственность за действия третьих лиц не
              несет. Администрация Сайта рекомендует Пользователям ответственно
              подходить к решению вопроса об объеме размещаемой личной
              информации о себе на Сайте.
            </p>
            <p>
              Администрация Сайта принимает технические и
              организационно-правовые меры в целях обеспечения защиты личных
              данных и иной информации Пользователей от неправомерного или
              случайного доступа к ним, уничтожения, изменения, блокирования,
              копирования, распространения, а также от иных неправомерных
              действий. Исключение составляют сведения, самостоятельно
              размещенные Пользователями в открытом доступе на Сайте и его
              сервисах. В случае копирования и распространения такой информации
              третьими лицами Администрация Сайта ответственности не несет.
            </p>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Предмет политики конфиденциальности
            </h6>
            <p>
              Настоящая Политика конфиденциальности устанавливает обязательства
              Администратора по неразглашению и обеспечению режима защиты
              конфиденциальности персональных данных, которые Пользователь
              предоставляет по запросу Администратора при регистрации и
              использовании Сайта.
            </p>
            <p>
              Персональные данные, разрешённые к обработке в рамках настоящей
              Политики конфиденциальности, предоставляются Пользователем путём
              использования настоящего сайта,
            </p>
            <p>
              Сайт в автоматическом режиме ведет необходимый для его
              функционирования сбор информации:
            </p>
            <ul>
              <li>
                <span>
                  IP адрес;
                </span>
              </li>
              <li>
                <span>
                  информация из cookies;
                </span>
              </li>
              <li>
                <span>
                  информация о браузере (или иной программе, которая
                  осуществляет доступ к показу рекламы);
                </span>
              </li>
              <li>
                <span>
                  время доступа;
                </span>
              </li>
              <li><span> уникальные идентификаторы устройств;</span></li>
              <li>
                <span>
                  иная информация, получаемая в ходе работы Сайта и связанная с
                  ним.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Цели сбора персональной информации Пользователя
            </h6>
            <p>
              Администратор может использовать персональные данные Пользователя,
              а также Cookie, в следующих целях:
            </p>
            <ul>
              <li>
                <span>
                  Создания учетной записи Пользователя, с его согласия
                </span>
              </li>
              <li>
                <span>
                  Идентификации Пользователя, зарегистрированного на Сайте.
                </span>
              </li>
              <li>
                <span>
                  Предоставления Пользователю доступа к персонализированным
                  ресурсам Сайта.
                </span>
              </li>
              <li>
                <span>
                  Установления с Пользователем обратной связи, включая
                  направление уведомлений, запросов, касающихся использования
                  Сайта, оказания услуг, обработка запросов и заявок от
                  Пользователя.
                </span>
              </li>
              <li>
                <span>
                  Для уведомления пользователя об изменениях, касающихся Сайта.
                </span>
              </li>
              <li>
                <span>
                  Проведения анализа, в ходе получения информации, с целью
                  улучшения функционала Сайта.
                </span>
              </li>
              <li>
                <span>
                  Для обнаружения, предотвращения и решения технических проблем.
                </span>
              </li>
              <li>
                <span>
                  Отслеживания использования Сайта.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Способы и сроки обработки персональной информации. Передача
              персональных данных. Хранение персональных данных.
            </h6>
            <p>
              Обработка персональных данных Пользователя осуществляется без
              ограничения срока, любым законным способом, в том числе в
              информационных системах персональных данных с использованием
              средств автоматизации или без использования таких средств, за
              исключением случаев предельно возможного срока хранения
              персональных данных.
            </p>
            <p>
              Персональные данные могут хранится на носителях стороннего
              ресурса, расположенных за территорией Администратора.
            </p>
            <p>
              Все персональные данные и информация, получаемая, в ходе работы
              Сайта хранится и используется в соответствии с законодательством.
            </p>
            <p>
              Администратор, с согласия Пользователя, вправе передавать
              персональные данные третьим лицам, в случае предоставления
              таковыми услуг для Администратора, связанными с задачами
              Пользователя. Третьи лица могут получить информацию только для
              выполнения поставленных задач и обязуются не раскрывать их, а
              также не использовать для сторонних задач.
            </p>
            <p>
              Персональные данные Пользователя могут быть переданы
              уполномоченным органам государственной власти только по основаниям
              и в порядке, установленным законодательством.
            </p>
            <p>
              При утрате или разглашении персональных данных Администратор
              информирует Пользователя об утрате или разглашении персональных
              данных.
            </p>
            <p>
              Администратор принимает необходимые организационные и технические
              меры для защиты персональной информации Пользователя от
              неправомерного или случайного доступа, уничтожения, изменения,
              блокирования, копирования, распространения, а также от иных
              неправомерных действий третьих лиц.
            </p>
            <p>
              В случае использования cookie-файлов, в соответствии с GDPR,
              настоящим Администрация предупреждает о предоставлении части
              файлов третьим-лицам, в соответствии с используемыми техническими
              средствами, аналитическими алгоритмами, программами.
            </p>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Обязательства сторон
            </h6>
            <p>
              Пользователь обязан:
            </p>
            <ul>
              <li>
                <span>
                  Предоставить информацию о персональных данных, необходимую для
                  пользования Сайта.
                </span>
              </li>
              <li>
                <span>
                  Обновить, дополнить предоставленную информацию о персональных
                  данных в случае изменения данной информации.
                </span>
              </li>
            </ul>
            <p>
              Администратор обязан:
            </p>
            <ul>
              <li>
                <span>
                  Использовать полученную информацию исключительно для целей,
                  указанных в п. 4 настоящей Политики конфиденциальности.
                </span>
              </li>
              <li>
                <span>
                  Обеспечить хранение конфиденциальной информации в тайне, не
                  разглашать без предварительного письменного разрешения
                  Пользователя, а также не осуществлять продажу, обмен,
                  опубликование, либо разглашение иными возможными способами
                  переданных персональных данных Пользователя, за исключением
                  требований предусмотренных законодательством.
                </span>
              </li>
              <li>
                <span>
                  Принимать меры предосторожности для защиты конфиденциальности
                  персональных данных Пользователя согласно порядку, обычно
                  используемого для защиты такого рода информации в существующем
                  деловом обороте.
                </span>
              </li>
              <li>
                <span>
                  Осуществить блокирование персональных данных, относящихся к
                  соответствующему Пользователю, с момента обращения или запроса
                  Пользователя или его законного представителя либо
                  уполномоченного органа по защите прав субъектов персональных
                  данных на период проверки, в случае выявления недостоверных
                  персональных данных или неправомерных действий.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Ответственность сторон
            </h6>
            <p>
              Администратор, не исполнивший свои обязательства, несёт
              ответственность за убытки, понесённые Пользователем в связи с
              неправомерным использованием персональных данных, в соответствии с
              законодательством.
            </p>
            <p>
              В случае утраты или разглашения Конфиденциальной информации
              Администратор не несёт ответственность, если данная
              конфиденциальная информация:
            </p>
            <ul>
              <li>
                <span>
                  Стала публичным достоянием до её утраты или разглашения.
                </span>
              </li>
              <li>
                <span>
                  Была получена от третьей стороны до момента её получения
                  Администратором.
                </span>
              </li>
              <li>
                <span>
                  Была разглашена с согласия Пользователя.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Дополнительные условия
            </h6>
            <p>
              Администратор вправе вносить изменения в настоящую Политику
              конфиденциальности без согласия Пользователя.
            </p>
            <p>
              Новая Политика конфиденциальности вступает в силу с момента ее
              размещения на Сайте, если иное не предусмотрено новой редакцией
              Политики конфиденциальности.
            </p>
            <p>
              Все предложения или вопросы по настоящей Политике
              конфиденциальности следует направлять на электронный адрес
              Администратора:
              <a href="mailto:info@otiumsoft.com" class="cookie__email"
                >info@otiumsoft.com</a
              >
            </p>
            <p>
              Действующая Политика конфиденциальности является комплексной и
              включает в себя Политику Cookie.
            </p>
          </div>
        </div>
      </div>
      <div class="cookie__wrapper">
        <div class="cookie__header">
          <h2 class="cookie__title title">
            Политика cookie
          </h2>
        </div>
        <div class="cookie__body">
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Введение
            </h6>
            <p>
              Общество с ограниченной ответственностью «ОциумСофт» (далее по
              тексту – «Администрация») приняло на себя обязательство
              предоставлять Вам информацию об используемых нами cookie-файлах.
            </p>
            <p>
              Одной из приоритетных задач Администрации является защита вашей
              личной информации, включая персональные данные, при этом мы
              стремимся обеспечить прозрачность процесса использования такой
              информации.
            </p>
            <p>
              Ниже представлена общая информация о том, что представляют собой
              cookie-файлы, которая в полной мере не отражает все возможные виды
              и типы cookie-файлов, их атрибуты, алгоритмы установки, условия
              истечения сроков хранения и способы удаления.
            </p>
            <p>
              В настоящем документе описываются собираемые данные о
              пользователях интернет-сайта https://www.otiumsoft.by (далее –
              Сайт) при использовании Администрацией cookie-файлов.
            </p>
            <p>
              Cookie — это небольшой текстовый файл, содержащий Ваши данные,
              который сохраняется при каждом посещении сайта или сервисов.
            </p>
            <p>
              Такие файлы помогают сайтам и приложениям запомнить Ваши
              предыдущие действия, даже если они были совершены Вами несколько
              месяцев или несколько лет назад. К таким действиям может
              относиться переход по определенным ссылкам или перемещение между
              страницами, вход в систему или выбор той или иной информации на
              странице. Существуют разные типы cookie-файлов, без которых
              интернет-сайты, приложения и прочие цифровые услуги не могли бы
              функционировать в привычном для Вас режиме.
            </p>
            <p>
              Например, при подтверждении входа в систему без cookie-файла
              интернет-сайт не смог бы отобразить Вашу персональную страницу,
              или же при каждом посещении интернет-сайта на экране появлялось бы
              сообщение «зарегистрируйтесь для входа в систему», так как
              интернет-сайт не мог бы запомнить Ваши учетные данные.
            </p>
            <p>
              Вы всегда можете удалить существующие cookie-файлы или даже
              полностью заблокировать их использование на интернет-сайтах.
            </p>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Типы cookie-файлов:
            </h6>
            <ul>
              <li>
                <span>
                  <b>
                    Строго необходимые Cookie-файлы.
                  </b>
                  <p>
                    Существуют cookie-файлы, без которых навигация по
                    интернет-сайтам, а также использование их функциональных
                    характеристик, таких как доступ к безопасным зонам, стали бы
                    невозможными. Без таких файлов пользователь не может
                    зарегистрироваться или войти в систему. Эти cookie-файлы не
                    сохраняют какую-либо информацию о Вас, которая может быть
                    использована в маркетинговых целях или для учета посещаемых
                    Вами сайтов в сети Интернет.
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <b>
                    Аналитические Cookie-файлы.
                  </b>
                  <p>
                    Эти файлы применяются для сбора информации о том, как
                    пользователи используют Сайт, например, какие страницы они
                    посещают чаще всего и получают ли сообщения об ошибках. Они
                    не применяются для сбора информации, позволяющей установить
                    личность пользователя; вся информация является анонимной.
                    Она используется исключительно для совершенствования
                    функциональных характеристик сайта или приложения, а также
                    использования третьими лицами, предоставляющими средства
                    аналитики cookie-файлов.
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <b>
                    Функциональные Cookie-файлы.
                  </b>
                  <p>
                    Такие файлы используются для сбора данных о пользовательских
                    настройках (например, имя пользователя, выбранный
                    пользователем язык или регион), на основании которых
                    веб-сайт предлагает пользователю усовершенствованные
                    персональные функции. Функциональные Cookie-файлы могут
                    запомнить информацию об изменениях, внесенных Вами в размер
                    текста или тип шрифта, а также о других настройках,
                    доступных для пользователей. Они также могут предоставлять
                    информацию об используемых Вами ресурсах интернет-сайтов,
                    таких как, например, просмотр видео-ролика или сохранение
                    комментария в блоге. При необходимости, информацию,
                    собираемую данными файлами, можно сделать анонимной. Они
                    также отслеживают другие сайты и приложения, которые Вы
                    посещаете.
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <b>
                    Целевые Cookie-файлы
                  </b>
                  <p>
                    Эти файлы позволяют отображать рекламу или сообщения на
                    интересующую Вас тему. Иногда целевые cookie-файлы могут
                    содержать ссылки на другие сайты, сайты рекламодателей.
                  </p>
                </span>
              </li>
            </ul>
            <p>
              Описанные выше типы cookie-файлов могут иметь характер «временных»
              (сеансовые» cookie-файлы) или «долгосрочных» (постоянные»
              cookie-файлы).
            </p>
            <ul>
              <li>
                <span>
                  <b>
                    «Сеансовые»
                  </b>
                  cookie-файлы автоматически удаляются после завершения
                  посещения интернет-сайта.
                </span>
              </li>
              <li>
                <span>
                  <b>
                    «Постоянные»
                  </b>
                  cookie-файлы остаются на вашем устройстве в течение
                  определенного периода времени. Они активируются автоматически
                  при посещении вами какого-либо веб-сайта или приложения, за
                  исключением случаев определенных настоящей Политикой Cookie.
                </span>
              </li>
            </ul>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Использование cookie-файлов Администрацией при посещении Сайта
            </h6>
            <p>
              Используемые Администрацией cookie-файлы при посещении Сайта
              содержат как техническую информацию (к такой информации относится
              тип Вашего интернет-браузера, тип операционной системы Вашего
              компьютера и доменное имя интернет-сайта, с помощью которого Вы
              вышли на Сайт), так и аналитическую, статистическую. Администрация
              Сайта оставляет за собой право дополнить перечень собираемой
              статистической информации посредством cookie.
            </p>
            <p>
              Перечень cookie-файлов, которые администрация использует: Google
              analytics – сервис Google используемый нами, для сбора
              статистической информации, с перечнем возможных к использованию
              cookie можно ознакомиться на 
              <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=ru" target="_blank">официальном сайте разработчика.</a>
            </p>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Удаление cookie-файлов, отказ от использования cookie.
            </h6>
            <p>
              Если Вы хотите отказаться от использования cookie-файлов при
              посещении Сайта, Вы можете удалить Cookie из папки с
              cookie-файлами в используемом Вами браузере. Изменить настройки
              используемого Вами браузера, чтобы блокировать cookie-файлы или
              инициировать предупреждающую надпись до сохранения какого-либо
              cookie-файла на Вашем компьютере, если используемый вами браузер
              обладает таким функционалом. Вы вправе обратиться на контактный
              e-mail с пометкой “cookie”, с просьбой преждевременно удалить
              сохраненные нами cookie файлы.
            </p>
            <p>
              Обращаем Ваше внимание, что некоторые части (модули) сайта не
              будут доступны для Вашего посещения (использования), если «cookie»
              отключены, не подлежат сбору.
            </p>
            <p>
              Администрация не может предоставить исчерпывающие инструкции по
              управлению cookie-файлами для всех типов существующих браузеров и
              операционных систем, но может предоставить ссылки на официальные
              ресурсы разработчиков наиболее востребованных браузеров: 
              <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=ru" target="_blank">Google</a>, 
              <a href="https://support.apple.com/ru-ru/guide/safari/sfri11471/mac" target="_blank">Safari</a>.
            </p>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Контактная информация
            </h6>
            <p>
              В случае возникновения вопросов, касающихся настоящей Политики
              конфиденциальности, Политики в отношении cookie-файлов, вы можете
              связаться с нами следующим способом:
            </p>
            <p>
              <b>ООО "ОциумСофт"</b>
            </p>
            <p>
              <b>
                ул. Захарова 29А, офис 77е
              </b>
            </p>
            <p>
              <b>
                220034 Минск, Беларусь
              </b>
            </p>
            <p>
              <b>
                <a href="mailto:info@otiumsoft.com" class="cookie__email"
                  >info@otiumsoft.com</a
                >
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style lang="scss" scoped>
.wrapper {
  flex-direction: column;
}
.cookie__wrapper {
  padding-top: 50px;
  @include for-tablet-portrait-up {
    padding-top: 70px;
  }
  @include for-desktop-up {
    padding-top: 60px;
  }
}
.privacy-policy,
.cookie {
  padding: 110px 10px 50px 10px;
  width: 100%;
  @include for-tablet-portrait-up {
    padding: 132px 15px 60px 15px;
  }
  @include for-tablet-landscape-up {
    padding: 142px 16px 70px 16px;
  }
  @include for-desktop-up {
    padding: 132px 0 106px 0;
  }
  &__title {
    @include H4-semi-bold();
    padding-bottom: 40px;
    color: var(--dark-blue-color);
    @include for-tablet-landscape-up {
      @include H2-semi-bold();
    }
    @include for-desktop-up {
      @include H1-semi-bold();
      padding-bottom: 60px;
    }
  }
  &__subtitle {
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include P1-bold();
    @include for-tablet-portrait-up {
      @include P1-bold();
      margin-bottom: 16px;
    }
    @include for-tablet-landscape-up {
      @include H5-bold();
      margin-bottom: 11px;
    }
    @include for-desktop-up {
      @include H5-bold();
    }
    .vertical-line {
      background: var(--dark-gradient-background);
      width: 4px;
      height: 30px;
      border-radius: 8px;
      margin-right: 8px;
      @include for-tablet-portrait-up {
        height: 34px;
        margin-right: 10px;
      }
      @include for-tablet-landscape-up {
        height: 42px;
      }
    }
  }
  &__block + &__block {
    margin-top: 30px;
  }
  &__block {
    p {
      @include P4();
      @include for-tablet-landscape-up {
        @include P3();
      }
    }
    p + p {
      margin-top: 8px;
      @include for-tablet-portrait-up {
        margin-top: 10px;
      }
    }
    ul {
      list-style-type: disc !important;
      list-style-position: outside !important;
      color: var(--dark-blue-color);
      padding: 0 0 0 17px !important;
      @include P4();
      @include for-tablet-landscape-up {
        @include P3();
      }
      li + li {
        margin-top: 8px;
        @include for-tablet-portrait-up {
          margin-top: 10px;
        }
      }
      li {
        span {
          color: var(--black-color);
        }
      }
    }
  }
  &__email {
    color: var(--dark-blue-color);
    font-weight: 600;
  }
  a{
    color: var(--black-color);
    &:hover{
      cursor: pointer;
    }
  }
}
.privacy-policy__wrapper {
  display: flex;
  flex-direction: column;
}
</style>
